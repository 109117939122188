import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";

const BlogDetails = ({ data, allData }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-1200 mx-auto">
        <div className="row">
          <div className="col-12 col-md-9">
            <h1 className="text-center mt-5 pink-text">{t(data.title)}</h1></div>
          <div className="col-12 col-md-3"></div>
        </div>
        <div className="row my-5">
          <div className="col-12 col-md-9">
            {data.image1 && (
              <div className="text-center overflow-hidden">
                <img src={`/images/blog/${data.image1}.webp`} alt="" className="mb-3 w-100"></img>
              </div>
            )}
            {data.text1 && (
              <p dangerouslySetInnerHTML={{ __html: t(data.text1) }}></p>
            )}
            {data.image2 && (
              <div className="text-center overflow-hidden">
                <img src={`/images/blog/${data.image2}.webp`} alt="" className="mb-3 w-100"></img>
              </div>
            )}
            {data.text2 && (
              <p dangerouslySetInnerHTML={{ __html: t(data.text2) }}></p>
            )}
            {data.image3 && (
              <div className="text-center overflow-hidden">
                <img src={`/images/blog/${data.image3}.webp`} alt="" className="mb-3 w-100"></img>
              </div>
            )}
            {data.text3 && (
              <p dangerouslySetInnerHTML={{ __html: t(data.text3) }}></p>
            )}
            {data.image4 && (
              <div className="text-center overflow-hidden">
                <img src={`/images/blog/${data.image4}.webp`} alt="" className="mb-3 w-100"></img>
              </div>
            )}
            {data.text4 && (
              <p dangerouslySetInnerHTML={{ __html: t(data.text4) }}></p>
            )}
            {data.image5 && (
              <div className="text-center overflow-hidden">
                <img src={`/images/blog/${data.image5}.webp`} alt="" className="mb-3 w-100"></img>
              </div>
            )}
            {data.text5 && (
              <p dangerouslySetInnerHTML={{ __html: t(data.text5) }}></p>
            )}
            {data.image6 && (
              <div className="text-center overflow-hidden">
                <img src={`/images/blog/${data.image6}.webp`} alt="" className="mb-3 w-100"></img>
              </div>
            )}
            {data.text6 && (
              <p dangerouslySetInnerHTML={{ __html: t(data.text6) }}></p>
            )}
          </div>
          <div className="col-12 col-md-3">
            <div className="other-articles">
              <h4 className="text-center pink-text mb-4">{t('other_articles')}</h4>
              <div className="other-articles-list">
                {allData.filter(item => item.id !== data.id).map((item, key) => (
                  <Link to={`/${item.category}/${item.id}`} className="text-decoration-none" key={key}>
                    <div className={`d-flex justify-align-between mb-2 py-2 blog-other-news ${key > 0 ? "border-top" : ""}`} >
                      <img src={`/images/blog/${item.image1}_small.webp`} alt="" className="w-25 h-25 me-2 rounded"></img>
                      <div>{t(item.title).toUpperCase()}</div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div className="follow-us text-center py-4">
              <h4 className="pink-text mb-2">{t('follow_us')}</h4>
              <a target="_blank" href="https://www.facebook.com/humanasrca" rel="noreferrer">
                <i className="bi bi-facebook pink-bg text-white px-1 rounded me-2"></i>
              </a>
              <a target="_blank" href="https://www.instagram.com/humanasrca/" rel="noreferrer">
                <i className="bi bi-instagram pink-bg text-white px-1 rounded me-2"></i>
              </a>
              <a target="_blank" href="https://www.youtube.com/channel/UC76h67ItnNF5inLWKSrI4fg" rel="noreferrer">
                <i className="bi bi-youtube pink-bg text-white px-1 rounded"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default BlogDetails