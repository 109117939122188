import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="super-light-pink-bg mt-5 py-4">
      <div className="w-1200 mx-auto text-center">
        <h1 className="py-3 m-0">{t('contact_us')}</h1>
        <p><i>{t('contact_us_subtitle')}</i></p>
        <div className="row g-0 pb-3">
          <div className="col-12 col-sm-6 col-md-4">
            <Link to="https://maps.app.goo.gl/g4DCfVb9nqA3u1ME6" className="text-decoration-none text-dark">
              <div className="d-flex m-0 justify-content-center align-items-center contact-box me-md-3 mb-3">
                <div><i className="bi bi-geo-alt-fill me-2"></i></div>
                <div>{t('address')}</div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <Link to="tel:011/6962-763" className="text-decoration-none text-dark">
              <div className="d-flex m-0 justify-content-center align-items-center contact-box me-md-3 mb-3">
                <div><i className="bi bi-telephone-fill me-2"></i></div>
                <div>011/6962-763</div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <Link to="mailto:someone@example.com" className="text-decoration-none text-dark">
              <div className="d-flex m-0 justify-content-center align-items-center contact-box me-md-3 mb-3">
                <div><i className="bi bi-envelope-fill me-2"></i></div>
                <div>office@humanasrca.rs</div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <Link to="https://www.instagram.com/humanasrca/" className="text-decoration-none text-dark" target="_blank">
              <div className="d-flex m-0 justify-content-center align-items-center contact-box me-md-3 mb-3">
                <div><i className="bi bi-instagram me-2"></i></div>
                <div>humanasrca</div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <Link to="https://www.facebook.com/humanasrca" className="text-decoration-none text-dark" target="_blank">
            <div className="d-flex m-0 justify-content-center align-items-center contact-box me-md-3 mb-3">
              <div><i className="bi bi-facebook me-2"></i></div>
              <div>Humana srca</div>
            </div>
            </Link>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <Link to="https://www.youtube.com/channel/UC76h67ItnNF5inLWKSrI4fg" className="text-decoration-none text-dark" target="_blank">
            <div className="d-flex m-0 justify-content-center align-items-center contact-box me-md-3 mb-3">
              <div><i className="bi bi-youtube me-2"></i></div>
              <div>Fondacija "Humana Srca"</div>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Contact