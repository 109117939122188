import { useState } from "react"
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const HeaderGray = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isLanguageEnglish, setIsIsLanguageEnglish] = useState(false);

  const location = useLocation();
  const { t } = useTranslation();

  const handleMenu = () => {
    setIsMenuOpened(!isMenuOpened)
  }

  const changeLanguage = () => {
    if (i18n.language === "sr") {
      i18n.changeLanguage("en");
      setIsIsLanguageEnglish(true);
    }
    else {
      i18n.changeLanguage("sr");
      setIsIsLanguageEnglish(false);
    }
  }

  return (
    <div className="header-blog">
      <div className="w-1200 mx-auto text-center">
        <div>
          <Link to="/"><img src="/images/logo/logo.webp" alt=""></img></Link>
        </div>
        <div className="pb-3">
          <Link to="/blog" className="text-decoration-none"><span className={`me-3 pe-3 blog-border-right ${location.pathname === "/blog" ? "active" : ""}`}>{t('home_blog').toUpperCase()}</span></Link>
          <Link to="/intervjui" className="text-decoration-none"><span className={`me-3 pe-3 blog-border-right ${location.pathname === "/intervjui" ? "active" : ""}`}>{t('interviews').toUpperCase()}</span></Link>
          <Link to="/nase-price" className="text-decoration-none"><span className={`${location.pathname === "/nase-price" ? "active" : ""}`}>{t('our_stories').toUpperCase()}</span></Link>
        </div>
      </div>
    </div>
  )
}
export default HeaderGray