import { useTranslation } from "react-i18next"
import Footer from "../components/Footer";
import ScrollToTop from "../scroll-to-top";
import { useEffect } from "react";
import Header from "../components/Header/Header";

const Awords = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      document.querySelectorAll('.aword-container .left').forEach(el => el.classList.add('show-in'))
    }, 200);
  })

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="header-img">
        <img src="/images/header-images/awords.jpg" alt="" className="w-100 d-none d-sm-block"></img>
        <img src="/images/header-images/awords_mobile.webp" alt="" className="w-100 d-sm-none"></img>
        <div className="overlay"></div>
        <div className="overlay2"></div>
        <div className="title">
          <p>{t('awords').toUpperCase()}</p>
        </div>
      </div>
      <div className="w-1200 mx-auto my-5 aword-container">
        <div className="row g-0 mb-5">
          <div className="col-12 col-sm-6 order-2 order-sm-1">
            <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_humanity_org_of_the_year').toUpperCase()}</h3>
            <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtitle_humanity_org_of_the_year')}</i></p>
            <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_humanity_org_of_the_year') }}></div>
          </div>
          <div className="col-12 col-sm-6 order-1 order-sm-2">
            <div className="text-center">
              <img src="/images/awords/awords10.webp" className="w-75 shadow" alt=""></img>
            </div>
          </div>
        </div>
        <div className="row g-0 mb-5">
          <div className="col-12 col-sm-6">
            <div className="text-center">
              <img src="/images/awords/awords9.webp" className="w-75 shadow" alt=""></img>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_humanity_work_aword').toUpperCase()}</h3>
            <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtitle_humanity_work_aword')}</i></p>
            <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_humanity_work_aword') }}></div>
          </div>
        </div>
        <div className="row g-0 mb-5">
          <div className="col-12 col-sm-6 order-2 order-sm-1">
            <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_aword_vlasotince').toUpperCase()}</h3>
            <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtitle_aword_vlasotince')}</i></p>
            <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_aword_vlasotince') }}></div>
          </div>
          <div className="col-12 col-sm-6 order-1 order-sm-2">
            <div className="text-center">
              <img src="/images/awords/aword_vlasotince.webp" className="w-75 shadow" alt=""></img>
            </div>
          </div>
        </div>
        <div className="row g-0">
          <div className="col-12 col-sm-6">
            <div className="text-center">
              <img src="/images/awords/awords1.webp" className="w-75 shadow" alt=""></img>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_humanity_aword').toUpperCase()}</h3>
            <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtitle_humanity_aword')}</i></p>
            <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_humanity_aword') }}></div>
          </div>
        </div>
        <div className="row g-0 mt-5">
          <div className="col-12 col-sm-6 order-2 order-sm-1">
            <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_familly_aword').toUpperCase()}</h3>
            <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtitle_familly_aword')}</i></p>
            <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_familly_aword') }}></div>
          </div>
          <div className="col-12 col-sm-6 order-1 order-sm-2">
            <div className="text-center">
              <img src="/images/awords/awords2.webp" className="w-75 shadow" alt=""></img>
            </div>
          </div>
        </div>


        <div className="row g-0 mt-5">
          <div className="col-12 col-md-6">
            <div className="text-center">
              <img src="/images/awords/awords8.webp" className="w-75 shadow" alt=""></img>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_aword_leskovac').toUpperCase()}</h3>
            <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtite_aword_leskovac')}</i></p>
            <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_aword_leskovac') }}></div>
          </div>
        </div>
        <div className="row g-0 mt-5">
          <div className="col-12 col-sm-6 order-2 order-sm-1">
            <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_eight_martch').toUpperCase()}</h3>
            <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtitle_eight_martch')}</i></p>
            <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_eight_martch') }}></div>
          </div>
          <div className="col-12 col-sm-6 order-1 order-sm-2">
            <div className="text-center">
              <img src="/images/awords/awords3.webp" className="w-75 shadow" alt=""></img>
            </div>
          </div>
        </div>


        <div className="row g-0 mt-5">
          <div className="col-12 col-md-6">
            <div className="text-center">
              <img src="/images/awords/awords4.webp" className="w-75 shadow" alt=""></img>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_sveti_sava').toUpperCase()}</h3>
            <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtitle_sveti_sava')}</i></p>
            <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_sveti_sava') }}></div>
          </div>
        </div>
        <div className="row g-0 mt-5">
          <div className="col-12 col-sm-6 order-2 order-sm-1">
            <h3 className="text-center text-md-start mt-4 mt-md-0">{t('title_danijela_leader').toUpperCase()}</h3>
            <p className="fw-bold gray-text text-center text-md-start"><i>{t('subtitle_danijela_leader')}</i></p>
            <div className="text-justify" dangerouslySetInnerHTML={{ __html: t('text_danijela_leader') }}></div>
          </div>
          <div className="col-12 col-sm-6 order-1 order-sm-2">
            <div className="text-center">
              <img src="/images/awords/awords7.webp" className="w-75 shadow" alt=""></img>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default Awords