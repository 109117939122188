import Footer from "../../components/Footer"
import Header from "../../components/Header/Header"
import ProjectsDetails2023 from "../../components/ProjectDetails2023"
import ScrollToTop from "../../scroll-to-top"

const AssociationsSupportDetails2023 = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <ProjectsDetails2023 />
      <Footer />
    </>
  )
}
export default AssociationsSupportDetails2023