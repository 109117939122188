import Contact from "../components/Contact";
import Footer from "../components/Footer"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import ScrollToTop from "../scroll-to-top";
import Header from "../components/Header/Header";

const BecomeMember = () => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="header-img">
        <img src="/images/header-images/become_member.jpg" alt="" className="w-100 d-none d-sm-block"></img>
        <img src="/images/header-images/become_member_mobile.webp" alt="" className="w-100 d-sm-none"></img>
        <div className="overlay"></div>
        <div className="overlay2"></div>
        <div className="title">
          <p className="subtitle mb-0">{t('need_help').toUpperCase()}</p>
          <p>{t('become_user').toUpperCase()}?</p>
        </div>
      </div>
      <div className="w-1200 mx-auto help">
        <div className="text-center become-member">
          <h1 className="page-title my-5">{t('two_types_of_help')}:</h1>
          <div className="row">
            <div className="col-6">
              <Link to="/individualna-pomoc" className="text-decoration-none text-dark">
                <div><img src="/images/icons/izdvojeni_projekti.webp" alt=""></img></div>
                <h3 className="mt-2">{t('individual_help')}</h3>
              </Link>
            </div>
            <div className="col-6">
              <Link to="/pomoc-za-udruzenja-i-organizacije" className="text-decoration-none text-dark">
                <div><img src="/images/icons/pozeli_zelju.webp" alt=""></img></div>
                <h3 className="mt-2">{t('organization_help')}</h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  )
}
export default BecomeMember