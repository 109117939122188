import { useTranslation } from "react-i18next"
import Footer from "../components/Footer"
import OthersAboutUsData from "../data/othersAboutUs.json"
import { Link } from "react-router-dom"
import ScrollToTop from "../scroll-to-top"
import Header from "../components/Header/Header"

const OthersAboutUs = () => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="header-img">
        <img src="/images/header-images/others_about_us.webp" alt="" className="w-100 d-none d-sm-block"></img>
        <img src="/images/header-images/others_about_us.webp" alt="" className="w-100 d-sm-none"></img>

        <div className="overlay"></div>
        <div className="overlay2"></div>
        <div className="title">
          <p>{t('title_others_about_us').toUpperCase()}</p>
        </div>
      </div>
      <div className="w-1200 mx-auto text-center mt-5">

        <div className="row mb-5 gy-4">
          {OthersAboutUsData.map((item, key) => (
            <div className="col-12 col-md-6 col-lg-3 others-about-us-card-container" key={key}>
              <div className="card h-100 super-light-pink-bg" role="button">
                <img className="card-img-top main-img" src={`images/others-about-us/${item.image}.webp`} alt=""></img>
                <div className="card-body d-flex flex-column justify-content-between">
                  <Link to={item.href} className="text-decoration-none text-dark"><h5 className="card-title pink-text pt-3 pb-5">{t(item.title).toUpperCase()}</h5></Link>
                  <div className="d-flex justify-content-between align-items-center">
                    <Link to={item.href} target="_blank" className="col-auto text-decoration-none news-button py-1 px-2">{t('read_news').toUpperCase()}</Link>
                    <Link to={item.newsHref} target="_blank" className="w-25">
                      <img src={`images/others-about-us/news-logo/${item.newsLogo}.webp`} alt="" className="text-end col w-100 news-logo"></img>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  )
}
export default OthersAboutUs