import Footer from "../../components/Footer"
import Header from "../../components/Header/Header"
import ProjectsDetails2022 from "../../components/ProjectDetails2022"
import ScrollToTop from "../../scroll-to-top"

const FamillyHelpsDetails2022 = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <ProjectsDetails2022 />
      <Footer />
    </>
  )
}
export default FamillyHelpsDetails2022