import Slider from "../Slider"
import cooperation from "../../data/cooperation.json"
import { Link } from "react-router-dom"

const Cooperation = () => {

  const responsive = [
    {
      itemsToShow: 2,
      maxWidth: 600,
    },
    {
      itemsToShow: 3,
      minWidth: 600,
    },
    {
      itemsToShow: 4,
      minWidth: 1200,
    },
    {
      itemsToShow: 6,
      minWidth: 1500,
    }
  ]

  return (
    <Slider responsiveProps={responsive}>
      {cooperation.map((item, key) => {
        return (
          <div
            key={key}
            style={{
              border: "15px solid transparent",
              textAlign: "center",
              boxSizing: "border-box",
              width: '150px'
            }}
          >
            <div className="about_item_container w-100 h-100 d-flex flex-column justify-content-between">
                <Link to={item.href}>
                <img src={`images/cooperation/${item.image}.webp`} className="card-img-top slider-image" alt=""></img>
                </Link>
                <p>{item.title}</p>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}
export default Cooperation