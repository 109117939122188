import Footer from "../components/Footer"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import ScrollToTop from "../scroll-to-top";
import Header from "../components/Header/Header";

const OrganizationHelp = () => {
  const { t } = useTranslation();
  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="w-1200 mx-auto individual-help mb-5">
        <h4 className="text-center my-5">{t('oh_condition_title')}:</h4>
        <div dangerouslySetInnerHTML={{ __html: t('oh_condition1') }}></div>
        <Link to="/documents/za-zasnivanje-korisnickog-odnosa-pravna-lica.pdf" target="_blank" className="text-decoration-none">
          <div className="pink2-bg p-3 text-light request-button  my-2" role="button">{t('oh_request1')}</div>
        </Link>
        <div dangerouslySetInnerHTML={{ __html: t('oh_condition2') }}></div>
        <div dangerouslySetInnerHTML={{ __html: t('oh_condition3') }}></div>
        <div dangerouslySetInnerHTML={{ __html: t('oh_condition4') }}></div>
        <div dangerouslySetInnerHTML={{ __html: t('oh_condition5') }}></div>
        <div dangerouslySetInnerHTML={{ __html: t('oh_condition6') }}></div>
        <Link to="/documents/o-obradi-podataka-o-licnosti.pdf" target="_blank" className="text-decoration-none">
          <div className="pink2-bg p-3 text-light request-button  my-2" role="button">{t('ih_request2').toUpperCase()}</div>
        </Link>
        <Link to="/documents/korisnika.pdf" target="_blank" className="text-decoration-none">
          <div className="pink2-bg p-3 text-light request-button  my-2" role="button">{t('ih_request3').toUpperCase()}</div>
        </Link>
        <p dangerouslySetInnerHTML={{ __html: t('ih_request_info') }} className="light-pink-bg p-3 mt-3"></p>
        <p dangerouslySetInnerHTML={{ __html: t('ih_request_info2') }} ></p>
        <Link to="/documents/o-nacinu-rada-i-pruzanju-usluga-korisnicima-Humana-Srca-Fondacije.pdf" target="_blank" className="text-decoration-none">
          <div className="pink2-bg p-3 text-light request-button  my-2" role="button">{t('ih_info_text').toUpperCase()}</div>
        </Link>
      </div>
      <Footer />
    </>
  )
}
export default OrganizationHelp