import { useTranslation } from "react-i18next"
import Footer from "../components/Footer";
import partners from "../data/partners.json"
import ScrollToTop from "../scroll-to-top";
import PartnerSlider from "../components/Sliders/CellebritiesSlider";
import Cooperation from "../components/Sliders/Cooperation";
import Header from "../components/Header/Header";

const Partners = () => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="header-img">
        <img src="/images/header-images/partners.webp" alt="" className="w-100 d-none d-sm-block"></img>
        <img src="/images/header-images/partners_mobile.webp" alt="" className="w-100 d-sm-none"></img>
        <div className="overlay"></div>
        <div className="overlay2"></div>
        <div className="title">
          <p className="subtitle mb-0">{t('with_friends').toUpperCase()}</p>
          <p>{t('our_partners').toUpperCase()}</p>
        </div>
      </div>
      <div className="partner-container pb-5">
        <div className="w-1200 mx-auto">
          <h3 className="text-center text-justify py-3 py-md-5">{t('text_partners')}</h3>
          <hr></hr>
          <div className="row">
            {partners.map((item, key) => (
              <div className="col-4 col-md-3 mt-3" key={key}>
                <img src={`/images/partners/${item}.webp`} alt="" className="w-100"></img>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <h1 className="text-center py-5">{t('cooperation')}</h1>
        <Cooperation/>
      </div>
      <div>
        <h1 className="text-center py-5">{t('supported_by')}</h1>
        <PartnerSlider />
      </div>
      <Footer />
    </>
  )
}
export default Partners