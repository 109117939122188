import { Link } from "react-router-dom"
import Footer from "../components/Footer"
import { useTranslation } from "react-i18next"
import blog from "../data/blog.json"
import ScrollToTop from "../scroll-to-top"
import HeaderGray from "../components/Header/HeaderBlog"

const OurStories = () => {
  const { t } = useTranslation();
  const ourStories = blog.filter(item => item.category === "nase-price")

  return (
    <>
      <ScrollToTop />
      <HeaderGray />
      <div className="our-stories-page">
        <div className="w-1200 mx-auto">
          <div className="section row align-item-center py-5">
            <div className="col px-2 my-auto">
              <div className="section-line green-bg"></div>
            </div>
            <div className="title col-auto"><h1 className="text-center">{t('our_stories').toUpperCase()}</h1></div>
            <div className="col px-2 my-auto">
              <div className="section-line green-bg"></div>
            </div>
          </div>
          <div className="pb-5">
            <div className="row my-5 our-stories">
              {ourStories.map((item, key) => (
                <div className="col-6 col-md-3 mb-4" key={key}>
                  <Link to={`/nase-price/${item.id}`} className="text-decoration-none text-dark">
                    <div className="card h-100 super-light-pink-bg project-card-container" role="button">
                      <img className="card-img-top" src={`images/blog/${item.small}.webp`} alt=""></img>
                      <div className="card-body d-flex flex-column justify-content-between">
                        <div>
                          <h5 className="card-title purple-text">{t(item.title)}</h5>
                        </div>
                        <div className="purple-text fw-bold mt-3">{t('read_more')}»</div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default OurStories