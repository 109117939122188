import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="header-line"></div>
      <div className="w-1200 mx-auto pb-2">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-4 text-center mt-3 mt-md-0">
            <div>office@humanasrca.rs</div>
            <div>011/696-2763</div>
          </div>
          <div className="col-12 col-md-4 text-center">
            <Link to="/"><img src="/images/logo/logo.webp" alt=""></img></Link>
            <div className="gray-text"><b>{t('footer_goals')}</b></div>
          </div>
          <div className="col-12 col-md-4 text-center mt-3 mt-md-0">
            <div>{t('follow_us')}</div>
            <div className="mt-2">
              <span className="me-2">
                <a target="_blank" href="https://www.facebook.com/humanasrca" rel="noreferrer">
                  <i className="bi bi-facebook pink-bg text-white px-1 rounded"></i>
                </a>
              </span>
              <span className="me-2">
                <a target="_blank" href="https://www.instagram.com/humanasrca/" rel="noreferrer">
                  <i className="bi bi-instagram pink-bg text-white px-1 rounded"></i>
                </a>
              </span>
              <span>
                <a target="_blank" href="https://www.youtube.com/channel/UC76h67ItnNF5inLWKSrI4fg" rel="noreferrer">
                  <i className="bi bi-youtube pink-bg text-white px-1 rounded"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer