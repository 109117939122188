import { useTranslation } from "react-i18next";
import Slider from "../Slider"
import cellebrities from "../../data/cellebrities.json"

const CellebritiesSlider = () => {
  const { t } = useTranslation();

  const responsive = [
    {
      itemsToShow: 2,
      maxWidth: 600,
    },
    {
      itemsToShow: 3,
      minWidth: 600,
    },
    {
      itemsToShow: 4,
      minWidth: 1200,
    },
    {
      itemsToShow: 6,
      minWidth: 1500,
    }
  ]

  return (
    <Slider responsiveProps={responsive}>
      {cellebrities.map((item, key) => {
        return (
          <div
            style={{
              border: "15px solid transparent",
              textAlign: "center",
              boxSizing: "border-box",
              width: '150px',
            }}
            key={key}
          >
            <div className="col-2 about_item_container mb-4 w-100">
              <img src={`/images/famousPersons/${item.image}.webp`} className="card-img-top slider-image" alt=""></img>
              <div>{t(item.name)}</div>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}
export default CellebritiesSlider