import DonateSection from "../components/DonateSection";
import Footer from "../components/Footer"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import ScrollToTop from "../scroll-to-top";
import Counter from "../components/Counter/Counter";
import Header from "../components/Header/Header";

const Finance = () => {
  const { t } = useTranslation();

  return(
    <>
    <ScrollToTop/>
    <Header />
      <div className="header-img">
        <img src="images/header-images/finance.webp" alt="" className="w-100 d-none d-sm-block"></img>
        <img src="images/header-images/finance_mobile.webp" alt="" className="w-100 d-sm-none"></img>
        <div className="overlay"></div>
        <div className="overlay2"></div>
        <div className="title">
          <p>{t('finance').toUpperCase()}</p>
        </div>
      </div>
      <div className="row">
        <div className="text-center">
        <h1 className="my-5">{t('report')}</h1>
        </div>
        <div className="row">
        <Link to="/reports/Godisnji-izvestaj.pdf" target="_blank" className="text-decoration-none">
          <div className="pink2-bg text-light finance request-button  my-2 mx-auto mb-5" role="button">{t('report1').toUpperCase()}</div>
        </Link>
        <Link to="/reports/Godisnji-izvestaj2023.pdf" target="_blank" className="text-decoration-none">
          <div className="pink2-bg text-light finance request-button  my-2 mx-auto mb-5" role="button">{t('report2').toUpperCase()}</div>
        </Link>
      </div>
      </div>
      <Counter/>
      <DonateSection/>
      <Footer />
    </>
  )
}
export default Finance