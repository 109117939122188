import Contact from "../components/Contact";
import Footer from "../components/Footer"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import ScrollToTop from "../scroll-to-top";
import Header from "../components/Header/Header";

const Donate = () => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop/>
      <Header />
      <div className="header-img">
        <img src="/images/header-images/donate.webp" alt="" className="w-100 d-none d-sm-block"></img>
        <img src="/images/header-images/donate_mobile.webp" alt="" className="w-100 d-sm-none"></img>
        <div className="overlay"></div>
        <div className="overlay2"></div>
        <div className="title">
          <p className="subtitle mb-0">{t('make_deed').toUpperCase()}</p>
          <p>{t('donate').toUpperCase()}</p>
        </div>
      </div>
      <div className="w-1200 mx-auto text-center donate-container">
        <h1 className="my-5">{t('donate_title')}</h1>
        <p className="text-justify">{t('donate_text')}</p>
        <h1 className="my-5">{t('donate_subtitle')}</h1>
        <div className="row g-0 my-3 donate-container">
          <div className="col-12 col-md-6 box">
            <div>
              <div className="box-container d-flex justify-content-center light-pink-bg me-md-2 h-100 p-4">
                <div className="mt-2">
                  <h3>Srpska banka:</h3>
                  <div className="account-number">295-0000001252714-41</div>
                  <div>
                    <Link to="/documents/popunjena_uplatnica-srpska-banka.pdf" target="_blank" className="text-decoration-none">
                      <div className="bg-white text-dark p-3 text-light request-button my-2 mx-auto" role="button">{t('download_payment_slip').toUpperCase()}</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 box">
            <div className="h-100">
              <div className="box-container d-flex justify-content-center light-pink-bg ms-md-2 mt-4 mt-md-0 h-100 p-4">
                <div className="mt-2">
                  <h3>Poštanska štedionica:</h3>
                  <div className="account-number">200-3474070101033-94</div>
                  <div>
                    <Link to="/documents/popunjena_uplatnica.pdf" target="_blank" className="text-decoration-none">
                      <div className="bg-white text-dark p-3 text-light request-button my-2 mx-auto" role="button">{t('download_payment_slip').toUpperCase()}</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4 mt-md-0 box">
            <div className="row g-0">
              <div className="box-container d-flex justify-content-center light-pink-bg mt-4 p-4">
                <div className="mt-2">
                  <h3>{t('payment')}</h3>
                  <div className="account-number">IBAN RS35295000000010760128</div>
                  <div>
                    <Link to="/documents/Instrukcija-ino-placanje.pdf" target="_blank" className="text-decoration-none">
                      <div className="bg-white text-dark p-3 text-light request-button my-2 mx-auto" role="button">{t('download_instructions').toUpperCase()}</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
      <div className="w-1200 mx-auto my-5">
        <div className="row g-0">
          <div className="col-12 col-md-6">
            <div className="me-md-5">
              <h5>{t('donate_subtitle2')}</h5>
              <div dangerouslySetInnerHTML={{ __html: t('donate_text1') }} className="text-justify"></div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-c">
            <div className="ms-md-5 mt-5 mt-md-0">
              <h5>{t('donate_subtitle3')}</h5>
              <div dangerouslySetInnerHTML={{ __html: t('donate_text2') }} className="text-justify"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default Donate