import { useState } from "react"
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isLanguageEnglish, setIsIsLanguageEnglish] = useState(false);
  const [isProjectSubmenuOpened, setisProjectSubmenuOpened] = useState(false)
  const [isAboutUsSubmenuOpened, setIsAboutUsSubmenuOpened] = useState(false)

  const [isMobileSubmenuProjectsOpened, setIsMobileSubmenuProjectsOpened] = useState(false);
  const [isMobileSubmenuAboutOpened, setIsMobileSubmenuAboutOpened] = useState(false);

  const location = useLocation()
  const { t } = useTranslation();

  const handleMenu = () => {
    setIsMenuOpened(!isMenuOpened)
  }

  const changeLanguage = () => {
    if (i18n.language === "sr") {
      i18n.changeLanguage("en");
      setIsIsLanguageEnglish(true);
    }
    else {
      i18n.changeLanguage("sr");
      setIsIsLanguageEnglish(false);
    }
  }

  const handleProjectSubmenu = () => {
    // setIsAboutUsSubmenuOpened(false);
    const projectSubmenu = document.querySelector('.project.dropdown-menu');
    projectSubmenu.style.display = isProjectSubmenuOpened === false ? "block" : "none";
    projectSubmenu.style.transform = "translate(0px, 43px)";
    setisProjectSubmenuOpened(!isProjectSubmenuOpened);
  }

  const handleAboutUsSubmenu = () => {
    // setisProjectSubmenuOpened(false);
    const projectSubmenu = document.querySelector('.about-us.dropdown-menu');
    projectSubmenu.style.display = isAboutUsSubmenuOpened === false ? "block" : "none";
    projectSubmenu.style.transform = "translate(0px, 43px)";
    setIsAboutUsSubmenuOpened(!isAboutUsSubmenuOpened);
  }

  return (
    <>
      <div className="d-none d-lg-flex header row justify-content-between align-items-center w-100 pt-2">
        <div className="col-2">
          <div>
            <Link to="/"><img src="/images/logo/logo1.webp" alt="Humana srca" className="w-100"></img></Link>
          </div>
        </div>
        <div className="menu col-10 d-flex g-0 justify-content-end align-items-center">
          <ul>
            <Link to="/"><li className={`me-3 ${location.pathname === "/" ? "active" : ""}`}>{t('home')}</li></Link>
            <li className={`me-3`} onClick={handleAboutUsSubmenu}>
              <div className="dropdown">
                <div className="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  {t('about_us')}
                </div>
                <ul className="about-us dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <Link to="/o-nama"><li className="p-2">{t('who_are_we')}?</li></Link>
                  <Link to="/rec-osnivaca"><li className="p-2">{t('founder_word')}</li></Link>
                  <Link to="/finansije"><li className="p-2">{t('finance')}</li></Link>
                  <Link to="/priznanja-i-nagrade"><li className="p-2">{t('awords')}</li></Link>
                  {<Link to="/drugi-o-nama"><li className="p-2">{t('others_about_us')}</li></Link>}
                </ul>
              </div>
            </li>
            <li className={`me-3`} onClick={handleProjectSubmenu}>
              <div className="dropdown">
                <div className="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  {t('projects')}
                </div>
                <ul className="project dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <Link to="/projekti-2024"><li className="p-2">{t('current_news')}</li></Link>
                <Link to="/projekti-2023"><li className="p-2">{t('projects_2023')}</li></Link>
                  <Link to="/projekti-2022"><li className="p-2">{t('projects_2022')}</li></Link>
                </ul>
              </div>
            </li>
            <Link to="/partners"><li className={`me-3 ${location.pathname === "/partners" ? "active" : ""}`}>{t('our_partners')}</li></Link>
            <Link to="/kako-da-postanem-korisnik"><li className={`me-3 ${location.pathname === "kako-da-postanem-korisnik" ? "active" : ""}`}>{t('become_user')}?</li></Link>
            <Link to="/blog"><li className={`me-3`}>{t('blog')}</li></Link>
          </ul>
          <Link to="/doniraj" className="button text-decoration-none me-2">{t('donate').toUpperCase()}</Link>
        </div>
      </div>
      <div className="mobile-header d-lg-none row g-0 justify-content-between align-items-center">
        <div className="col">
          <div className="p-3">
            <Link to="/"><img src="/images/logo/logo1.webp" className="w-75" alt="Humana srca"></img></Link>
          </div>
        </div>
        <div className="col-auto row g-0">
          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center">
              {/* <img src={`/images/language/${!isLanguageEnglish ? "srb.svg" : "eng.svg"}`} alt="" className="w-50" role="button" onClick={changeLanguage}></img> */}
            </div>
            <div className="burger me-2 p-0 px-1" role="button" onClick={handleMenu}>
              <i className={`${isMenuOpened ? "bi bi-x" : "bi bi-list"}`}></i>
            </div>
          </div>
        </div>
        {isMenuOpened && (
          <div className="my-3">
            <ul>
              <Link to="/" className="text-decoration-none"><li className={`py-2 me-3 ${location.pathname === "/" ? "active" : ""}`}><b>{t('home')}</b></li></Link>
              <li className={` py-2 me-3 ${location.pathname === "/o-nama" ? "active" : ""}`} onClick={() => setIsMobileSubmenuAboutOpened(!isMobileSubmenuAboutOpened)}><b>{t('about_us')}</b></li>
              <div className={`mobile-submenu-projects ${isMobileSubmenuAboutOpened ? "d-block" : "d-none"}`}>
                <Link to="/o-nama" className="text-decoration-none"><li className={`p-2 me-3 ${location.pathname === "/" ? "active" : ""}`}>{t('who_are_we')}?</li></Link>
                <Link to="/rec-osnivaca" className="text-decoration-none"><li className={` p-2 me-3 ${location.pathname === "/o-nama" ? "active" : ""}`}>{t('founder_word')}</li></Link>
                <Link to="/finansije" className="text-decoration-none"><li className={`p-2 me-3 ${location.pathname === "/" ? "active" : ""}`}>{t('finance')}</li></Link>
                <Link to="/priznanja-i-nagrade" className="text-decoration-none"><li className={` p-2 me-3 ${location.pathname === "/o-nama" ? "active" : ""}`}>{t('awords')}</li></Link>
                { <Link to="/drugi-o-nama" className="text-decoration-none"><li className="p-2">{t('others_about_us')}</li></Link> }
              </div>
              <li className={`py-2 me-3`} onClick={() => setIsMobileSubmenuProjectsOpened(!isMobileSubmenuProjectsOpened)}><b>{t('projects')}</b></li>
              <div className={`mobile-submenu-projects ${isMobileSubmenuProjectsOpened ? "d-block" : "d-none"}`}>
              <Link to="/projekti-2024" className="text-decoration-none"><li className={`p-2 py-1 me-3 ${location.pathname === "/partners" ? "active" : ""}`}>{t('current_news')}</li></Link>
              <Link to="/projekti-2023" className="text-decoration-none"><li className={`p-2 py-1 me-3 ${location.pathname === "/partners" ? "active" : ""}`}>{t('projects_2023')}</li></Link>
                <Link to="/projekti-2022" className="text-decoration-none"><li className={`p-2 py-1 me-3 ${location.pathname === "kako-da-postanem-korisnik" ? "active" : ""}`}>{t('projects_2022')}</li></Link>
              </div>
              <Link to="/partners" className="text-decoration-none"><li className={`py-2 me-3 ${location.pathname === "/partners" ? "active" : ""}`}><b>{t('our_partners')}</b></li></Link>
              <Link to="/kako-da-postanem-korisnik" className="text-decoration-none"><li className={`py-2 me-3 ${location.pathname === "kako-da-postanem-korisnik" ? "active" : ""}`}><b>{t('become_user')}?</b></li></Link>
              <Link to="/blog" className="text-decoration-none"><li className={`pt-2 pb-3 me-3 ${location.pathname === "/blog" ? "active" : ""}`}><b>{t('blog')}</b></li></Link>
              <Link to="/doniraj" className="text-decoration-none mt-2"><li className={`donate-mobile-button text-light pt-3 me-3 ${location.pathname === "kako-da-postanem-korisnik" ? "active" : ""}`}>{t('donate').toUpperCase()}</li></Link>
            </ul>
          </div>
        )}
      </div>
    </>
  )
}
export default Header