import Footer from "../components/Footer"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import ScrollToTop from "../scroll-to-top";
import Header from "../components/Header/Header";

const IndividualHelp = () => {
  const { t } = useTranslation();
  return (
    <>
      <ScrollToTop/>
      <Header />
      <div className="w-1200 mx-auto individual-help mb-5">
        <h4 className="text-center my-5">{t('ih_condition_title')}:</h4>
        <div dangerouslySetInnerHTML={{ __html: t('ih_condition1') }}></div>
        <Link to="/documents/za-zasnivanje-korisnickog-odnosa-fizicka-lica.pdf" target="_blank" className="text-decoration-none">
          <div className="pink2-bg p-3 text-light request-button  my-2" role="button">{t('ih_request1')}</div>
        </Link>
        <div dangerouslySetInnerHTML={{ __html: t('ih_condition2') }}></div>
        <Link to="/documents/o-obradi-podataka-o-licnosti.pdf" target="_blank" className="text-decoration-none">
          <div className="pink2-bg p-3 text-light request-button  my-2" role="button">{t('ih_request2').toUpperCase()}</div>
        </Link>
        <Link to="/documents/korisnika.pdf" target="_blank" className="text-decoration-none">
          <div className="pink2-bg p-3 text-light request-button  my-2" role="button">{t('ih_request3').toUpperCase()}</div>
        </Link>
        <Link to="/documents/ovlascenog-podnosioca-zahteva.pdf" target="_blank" className="text-decoration-none">
          <div className="pink2-bg p-3 text-light request-button  my-2" role="button">{t('ih_request4').toUpperCase()}</div>
        </Link>
        <p><i>{t('ih_request_note')}</i></p>
        <div dangerouslySetInnerHTML={{ __html: t('ih_condition3') }}></div>
        <div dangerouslySetInnerHTML={{ __html: t('ih_condition4') }}></div>
        <div dangerouslySetInnerHTML={{ __html: t('ih_condition5') }}></div>
        <div dangerouslySetInnerHTML={{ __html: t('ih_condition6') }}></div>
        <div dangerouslySetInnerHTML={{ __html: t('ih_condition7') }}></div>
        <div dangerouslySetInnerHTML={{ __html: t('ih_condition8') }}></div>
        <div dangerouslySetInnerHTML={{ __html: t('ih_condition9') }}></div>
        <div dangerouslySetInnerHTML={{ __html: t('ih_condition10') }}></div>
        <div dangerouslySetInnerHTML={{ __html: t('ih_condition11') }}></div>
        <div>{t('ih_condition12')}</div>
        <p dangerouslySetInnerHTML={{ __html: t('ih_request_info') }} className="light-pink-bg p-3 mt-3"></p>
        <p dangerouslySetInnerHTML={{ __html: t('ih_request_info2') }} ></p>
        <Link to="/documents/o-nacinu-rada-i-pruzanju-usluga-korisnicima-Humana-Srca-Fondacije.pdf" target="_blank" className="text-decoration-none">
          <div className="pink2-bg p-3 text-light request-button  my-2" role="button">{t('ih_info_text').toUpperCase()}</div>
        </Link>
      </div>
      <Footer />
    </>
  )
}
export default IndividualHelp