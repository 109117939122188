import { Link } from "react-router-dom"
import Footer from "../components/Footer"
import { useTranslation } from "react-i18next"
import blog from "../data/blog.json"
import ScrollToTop from "../scroll-to-top"
import HeaderGray from "../components/Header/HeaderBlog"

const Interviews = () => {
  const { t } = useTranslation();
  const interviews = blog.filter(item => item.category === "intervjui");

  return (
    <>
      <ScrollToTop />
      <HeaderGray />
      <div className="interview-page">
        <div className="w-1200 mx-auto">
          <div className="section row align-item-center py-5">
            <div className="col px-2 my-auto">
              <div className="section-line green-bg"></div>
            </div>
            <div className="title col-auto"><h1 className="text-center">{t('interviews').toUpperCase()}</h1></div>
            <div className="col px-2 my-auto">
              <div className="section-line green-bg"></div>
            </div>
          </div>
          <div className="row py-5 interviews">
            {interviews.map((item, key) => (
              <div className="col-6 col-md-4 col-lg-3 mb-4" key={key}>
                <Link to={`/intervjui/${item.id}`} className="text-decoration-none text-dark">
                  <div className="card h-100 super-light-pink-bg project-card-container" role="button">
                    <img className="card-img-top" src={`images/blog/${item.image1}_small.webp`} alt=""></img>
                    <div className="card-body d-flex flex-column justify-content-between">
                      <div>
                        <h5 className="card-title green-text">{t(item.title)}</h5>
                      </div>
                      <div className="green-text fw-bold mt-3">{t('read_more')}»</div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Interviews