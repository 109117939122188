import ReactVisibilitySensor from "react-visibility-sensor"
import CountUp from "react-countup"
import { useState } from "react";
import { useTranslation } from "react-i18next"
import counter from "../../data/counter.json"

const Counter = () => {
  const { t } = useTranslation();
  const [didViewCountUp, setDidViewCountUp] = useState(false);

  const onVisibilityChange = isVisible => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  return(
    <div className="super-light-pink-bg pb-5">
      <h1 className="text-center py-5">{t('our_results')}</h1>
        <div className="w-1200 mx-auto">
          <div className="row row-cols-sm-2 row-cols-auto mb-n6 text-center">
            {counter.map((item, key) => (
              <div className="col-12 col-sm-6 col-xl-3 mb-6" key={key}>
                <div className="about-funfact">
                  
                <i className={`${item.icon} pink-text counter-icon`}></i>
                  <div className="number counter-number">
                    <ReactVisibilitySensor
                      onChange={onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                    <CountUp end={didViewCountUp ? item.number : 0} />
                    </ReactVisibilitySensor>{item.text === 'supported_children' ? "+" : ""}
                  </div>
                  <p className="counter-text">{t(item.text)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
  )
}

export default Counter