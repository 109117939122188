import { useTranslation } from "react-i18next"
import Footer from "../components/Footer";
import ScrollToTop from "../scroll-to-top";
import Header from "../components/Header/Header";

const FoundationWord = () => {

  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="header-img">
        <img src="/images/header-images/foundation-word.jpg" alt="" className="w-100 d-none d-sm-block"></img>
        <img src="/images/header-images/foundation-word_mobile.webp" alt="" className="w-100 d-sm-none"></img>
        <div className="overlay"></div>
        <div className="overlay2"></div>
        <div className="title">
          <p>{t('founder_word').toUpperCase()}</p>
        </div>
      </div>
      <div className="w-1200 text-center mx-auto mb-5">
        <div className="avatar mx-auto my-5">
          <img src="/images/foundation-word/avatar.webp" alt="" className="w-100"></img>
        </div>
        <div className="text-justify"><i>{t('foundation_word')}</i></div>
        <div className="text-end mt-3 fw-bold">{t('foundation_word_author')}</div>
        <div className="text-end mb-3 fw-bold">{t('foundation_word_date')}</div>
      </div>
      <Footer />
    </>
  )
}
export default FoundationWord