import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";

const DonateSection = () => {
  const { t } = useTranslation();

  return (
    <div className="donate-section">
      <div className="header-img">
        <img src="images/other/donate.webp" alt="" className="w-100 d-none d-sm-block"></img>
        <img src="images/other/donate_mobile.webp" alt="" className="w-100 d-sm-none"></img>
        <div className="overlay"></div>
      </div>
      <div className="donate-section-content text-light text-center">
        <div className="subtitle">{t('donate_question')}?</div>
        <h1 className="title my-3">{t('be_donator')}!</h1>
        <Link to="/doniraj" className="text-decoration-none text-light"><div className="pink2-bg donate mx-auto">{t('donate').toUpperCase()}</div></Link>
      </div>
    </div>
  )
}
export default DonateSection