import { Carousel } from "react-responsive-carousel"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";

const carouselData = [

  {
    id: 1,
    image: "images/carousel/mamanisisama_dobitnici.webp",
    title: "mama_nisi_sama_dobitnici_title",
    subtitle: "mama_nisi_sama_dobitnici_subtitle",
    buttonText: "more_about_project",
    href: "/projekti-2024/izdvojeni-projekti/30"
  },
  {
    id: 2,
    image: "images/carousel/donatorsko.webp",
    title: "donatorsko_vece_title",
    subtitle: "donatorsko_vece_subtitle",
    buttonText: "more_about_project",
    href: "/projekti-2023/izdvojeni-projekti/18"
  },
  {
    id: 3,
    image: "images/carousel/strong_woman.webp",
    title: "strong_woman_title",
    subtitle: "strong_woman_subtitle",
    buttonText: "more_about_project",
    href: "/projekti-2023/osnazena-zena"
  },
  {
    id: 4,
    image: "images/carousel/baby_treasure.webp",
    title: "baby_treasure",
    subtitle: "baby_treasure_subtitle",
    buttonText: "more_about_project",
    href: "/projekti-2023/izdvojeni-projekti/34"
  },
  {
    id: 5,
    image: "images/carousel/make_wish.webp",
    title: "make_wish_title",
    subtitle: "make_wish_subtitle",
    buttonText: "more_about_project",
    href: "/projekti-2022/pozeli-zelju"
  }
]

const CustomCarousel = () => {
  const { t } = useTranslation();

  return (
    <Carousel infiniteLoop autoPlay interval={3000} showArrows showThumbs={false}>
      {carouselData.map((item, key) => (
        <div className="custom-carousel" key={key}>
          <div className="overlay"></div>
          <img src={item.image} className="w-100" alt="" />
          <div className="legend">
            <h3 className="title">{t(item.title).toUpperCase()}</h3>
            <p className="subtitle">{t(item.subtitle)}</p>
            <Link to={item.href}>
              <div className="btn">{t(item.buttonText)}</div>
            </Link>
          </div>
        </div>
      ))}
    </Carousel>
  )
}
export default CustomCarousel