import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";

const ProjectCard = ({ data, url }) => {
  const { t } = useTranslation();

  return (
    <Link to={`${url}/${data.id}`} className="text-decoration-none text-dark">
      <div className="card h-100 super-light-pink-bg project-card-container" role="button">
        <img className="card-img-top" src={data.mainImageSmall} alt=""></img>
        <div className="card-body d-flex flex-column justify-content-between">
          <div>
            <h5 className="card-title pink-text">{t(data.title)}</h5>
            <p className="card-text trim-4" dangerouslySetInnerHTML={{ __html: t(data.text) }}></p>
          </div>
          <div className="pink-text fw-bold mt-3">{t('read_more')}»</div>
        </div>
        <hr></hr>
        <div className="ms-3 light-gray-text pb-3">{t(data.date)}</div>
      </div>
    </Link>
  )
}
export default ProjectCard