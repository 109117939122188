import BlogDetails from "../components/BlogDetails"
import Footer from "../components/Footer"
import ScrollToTop from "../scroll-to-top"
import blog from "../data/blog.json"
import { useParams } from "react-router"
import HeaderGray from "../components/Header/HeaderBlog"

const OurStoriesDetails = () => {
  const { id } = useParams()
  const ourStories = blog.find(item => Number(item.id) === Number(id));
  const ourStoriesAll = blog.filter(item => item.category === "nase-price");

  return (
    <>
      <ScrollToTop />
      <HeaderGray />
      <BlogDetails data={ourStories} allData={ourStoriesAll} />
      <Footer />
    </>
  )
}
export default OurStoriesDetails