import projects2024 from "../data/Projects2024.json"
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next"
import ReactImageGallery from "react-image-gallery";
import { useEffect, useState } from "react";

const ProjectsDetails2024 = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const project = projects2024.find(item => item.id === Number(id))
  const [startIndex, setStartIndex] = useState();
  const [isGalleryOpened, setIsGalleryOpened] = useState(false);

  const openGallery = (event) => {
    document.querySelector('.image-gallery').classList.add('showGallery');
    setStartIndex(Number(event.target.id))
    setIsGalleryOpened(true);
  }

  const closeGallery = () => {
    document.querySelector('.image-gallery').classList.remove('showGallery');
    setIsGalleryOpened(false);
    setStartIndex(null);
  }

  useEffect(() => {
    const gallery = document.querySelector('.image-gallery-slides');
    const close = document.createElement('div');
    const line1 = document.createElement('div');
    line1.classList.add('line1');
    const line2 = document.createElement('div');
    line2.classList.add('line2');
    close.classList.add('gallery-close-button');
    close.appendChild(line1);
    close.appendChild(line2);

    close.addEventListener('click', closeGallery);
    gallery.appendChild(close);
  }, [projects2024])

  return (
    <>
      <div className="w-1200 mx-auto text-center project-details mb-5">
        <h1 className="my-5">{t(project.title)}</h1>
        <img src={project.mainImage} alt="" className="w-75"></img>
        <p className="text-justify mt-3" dangerouslySetInnerHTML={{ __html: t(project.text) }}></p>
        {project.youtube && (
          <iframe src={project.youtube} width="100%" height={500} title={project.title}></iframe>
        )}
        {project.gallery && (
          <div className="row">
            {project.gallery.map((item, key) => (
              <div className="col-4 mb-3" key={key}>
                <img src={item.thumbnail} id={key} alt="" className="w-100" onClick={openGallery} role="button"></img>
              </div>
            ))}
          </div>
        )}
        <ReactImageGallery
          items={project.gallery}
          startIndex={startIndex}
          showPlayButton={false}
          showFullscreenButton={false}
        />
      </div>
    </>
  )
}
export default ProjectsDetails2024