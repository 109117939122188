import { Link } from "react-router-dom"
import blog from "../data/blog.json"
import { useTranslation } from "react-i18next"
import Footer from "../components/Footer"
import Counter2 from "../components/Counter/Counter2"
import ScrollToTop from "../scroll-to-top"
import HeaderGray from "../components/Header/HeaderBlog"

const Blog = () => {
  const { t } = useTranslation();
  const firstNews = blog[0];
  const interviews = blog.filter(item => item.category === "intervjui");
  const ourStories = blog.filter(item => item.category === "nase-price");

  return (
    <>
      <ScrollToTop />
      <HeaderGray />
      <div className="video-box">
        <video
          className="d-block"
          src="/videos/mapa-gradova.mp4"
          type="video/mp4"
          playsInline
          autoPlay
          muted
          loop
        />
      </div>
      <div className="blog-container">
        <div className="w-1200 mx-auto">
          <div className="section row align-item-center py-5">
            <div className="col px-2 my-auto">
              <div className="section-line pink-bg"></div>
            </div>
            <div className="title col-auto"><h1 className="text-center">{t('new_articles').toUpperCase()}</h1></div>
            <div className="col px-2 my-auto">
              <div className="section-line pink-bg"></div>
            </div>
          </div>
          <div className="row g-0 text-start home-news pb-5">
            <div className="col-12 col-md-6 first-news">
              <Link className="text-decoration-none" to={`/${firstNews.category}/${firstNews.id}`}>
                <div className="first-news-container">
                  <img src={`images/blog/${firstNews.coverImage}.webp`} alt="" className="w-100"></img>
                  <div className="row g-0 align-items-end description w-100">
                    <div className="title ps-3">{t(firstNews.title).toUpperCase()}</div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-6 home-other-news">
              <div className="d-flex flex-column justify-content-between ms-md-3 mt-2 mt-md-0">
                {blog.filter(item => (item.id !== firstNews.id)).slice(1, 4).map((item, key) => (
                  <Link to={`/${item.category}/${item.id}`} className="text-decoration-none" key={key}>
                    <div className={`news-container row g-0 ${key > 0 ? 'mt-2' : ""}`}>
                      <div className="cover-image col-6 col-sm-4">
                        <img src={`images/blog/${item.coverImage}_small.webp`} alt="" className="w-100"></img>
                      </div>
                      <div className="col-6 col-sm-8">
                        <div className="info row g-0 justify-content-between">
                          <div className="title">{t(item.title).toUpperCase()}</div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="pb-5">
            <div className="section row align-item-center">
              <div className="title col-auto">{t('interviews').toUpperCase()}</div>
              <div className="col px-2 my-auto">
                <div className="section-line green-bg"></div>
              </div>
              <Link to="/intervjui" className="button col-auto text-decoration-none py-1 green-bg">{t('show_all').toUpperCase()}</Link>
            </div>
            <div className="row my-5 blog-interviews">
              {interviews.slice(0, 4).map((item, key) => (
                <div className="col-6 col-lg-3 mb-4" key={key}>
                  <Link to={`/intervjui/${item.id}`} className="text-decoration-none text-dark">
                    <div className="card h-100 super-light-pink-bg project-card-container" role="button">
                      <img className="card-img-top" src={`/images/blog/${item.small}.webp`} alt=""></img>
                      <div className="card-body d-flex flex-column justify-content-between">
                        <div>
                          <h5 className="card-title green-text">{t(item.title)}</h5>
                        </div>
                        <div className="green-text fw-bold mt-3">{t('read')} »</div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="pb-5 our-stories">
            <div className="section row align-item-center">
              <div className="title col-auto">{t('our_stories').toUpperCase()}</div>
              <div className="col px-2 my-auto">
                <div className="section-line purple-bg"></div>
              </div>
              <Link to="/nase-price" className="button col-auto text-decoration-none py-1 purple-bg">{t('show_all').toUpperCase()}</Link>
            </div>
            <div className="row my-5 blog-our-stories">
              {ourStories.slice(0, 4).map((item, key) => (
                <div className="col-6 col-lg-3 mb-4" key={key}>
                  <Link to={`/nase-price/${item.id}`} className="text-decoration-none text-dark">
                    <div className="card h-100 super-light-pink-bg project-card-container" role="button">
                      <img className="card-img-top" src={`/images/blog/${item.small}.webp`} alt=""></img>
                      <div className="card-body d-flex flex-column justify-content-between">
                        <div>
                          <h5 className="card-title purple-text">{t(item.title)}</h5>
                        </div>
                        <div className="purple-text fw-bold mt-3">{t('read')} »</div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="pb-5">
            <Counter2 />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default Blog