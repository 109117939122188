import { useParams } from "react-router"
import BlogDetails from "../components/BlogDetails"
import Footer from "../components/Footer"
import blog from "../data/blog.json"
import ScrollToTop from "../scroll-to-top"
import HeaderGray from "../components/Header/HeaderBlog"

const InterviewDetails = () => {
  const { id } = useParams()
  const interview = blog.find(item => Number(item.id) === Number(id));
  const allIntervies = blog.filter(item => item.category === "intervjui");

  return (
    <>
      <ScrollToTop />
      <HeaderGray />
      <BlogDetails data={interview} allData={allIntervies} />
      <Footer />
    </>
  )
}
export default InterviewDetails