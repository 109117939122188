import Slider from "../Slider"
import partners from "../../data/partners.json"

const PartnerSlider = () => {

  const responsive = [
    {
      itemsToShow: 2,
      maxWidth: 600,
    },
    {
      itemsToShow: 3,
      minWidth: 600,
    },
    {
      itemsToShow: 4,
      minWidth: 1200,
    },
    {
      itemsToShow: 6,
      minWidth: 1500,
    }
  ]
  
  return (
    <Slider responsiveProps={responsive}>
      {partners.map((item, key) => {
        return (
          <div
            key={key}
            style={{
              border: "15px solid transparent",
              textAlign: "center",
              boxSizing: "border-box",
              width: '150px'
            }}
          >
            <div className="about_item_container w-100">
              <img src={`/images/partners/${item}.webp`} className="card-img-top slider-image" alt=""></img>
            </div>
          </div>
        )
      })}
    </Slider>
  )
}
export default PartnerSlider