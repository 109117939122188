import Footer from "../../components/Footer"
import projects2023 from "../../data/Projects2023.json"
import ProjectCard from "../../components/ProjectCard"
import { useTranslation } from "react-i18next"
import ScrollToTop from "../../scroll-to-top"
import Header from "../../components/Header/Header"

const FamillyHelpList2023 = () => {
  const { t } = useTranslation();
  const associationsSupport = projects2023.filter(item => item.category === 'pomoc-porodicama')
  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="project-category-list-container pb-5">
        <div className="text-center my-5">
          <h1>{t('familly_help_title')}</h1>
          <h6 className="gray-text px-2"><i>{t('familly_help_text')}</i></h6>
        </div>
        <div className="w-1200 mx-auto row gy-4">
          {associationsSupport.map((item, key) => (
            <div className="col-12 col-sm-6 col-md-4" key={key}>
              <ProjectCard url="/projekti-2023/pomoc-porodicama" data={item} />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  )
}
export default FamillyHelpList2023